import React from "react"
import styles from "./faq.module.css"
import Title from "../title"
import FAQComponent from "../faqComponent"

export default function FAQ() {
    return (
      <div id='faq' className={styles.main}>
          <div className={styles.title}>
              <Title title='Frequently Asked Questions' subtitle="Find the answers to your questions below"/>
          </div>

          <div className={styles.panels}>
              <div className={styles.panel}>
                  <FAQComponent
                    item={{
                        title: "Design", items: [
                            {
                                question: "How long does it take to receive the design layout?\t",
                                answer: (<p>We usually send the design layout in 24-72 hours after receiving the photos.</p>)
                            },
                           {
                                question: "How long does it take to manufacture the album?",
                                answer: (<p>It takes us 48-72 hours to manufacture the album under normal circumstances or 24 hours if you're using the 'rush order' upgrade.</p>)
                            },
                           {
                                question: "What happens to my photos after I receive my album?",
                                answer: (<p>We delete all photos forever.</p>)
                            }
                        ]
                    }}/>
              </div>
              <div className={styles.panel}>
                  <FAQComponent
                    item={{
                        title: "Design", items: [
                            {
                                question: "I would like to order a custom size. Is this possible?",
                                answer: (<p>Yes, please contact us for more info about custom orders using our contact section.</p>)
                            },
                            {
                                question: "How many revisions are included in the design?",
                                answer: (<p>2 revisions are included, additional revisions cost $15 each</p>)
                            },
                            {
                                question: "How long does shipping take?",
                                answer: (<p>Depends on your location, usually between 1-5 working days. Please refer to our estimate tool in the Pricing section.</p>)
                            }
                        ]
                    }}/>
              </div>
          </div>
      </div>
    )
}
