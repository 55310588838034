import React, { useEffect, useState } from "react"
import styles from "./pricing.module.css"
import Title from "../title"
import buttonStyles from "../../styles/buttons.module.css"
import { pricingFlap, pricingSquare } from "../../config/styleImages"
import * as classnames from "classnames"
import PricingPanel from "../pricingPanel"

export default function Pricing({ images }) {

    const [prices, setPrices] = useState(null);
    const [measurement, setMeasurement] = useState("CM")
    const [currency, setCurrency] = useState("EUR")

    const currenciesSymbols = {
        "EUR": "€",
        "USD": "$",
        "AUD": "A$",
        "CAD": "C$",
    }

    const flapSizes = [
        {
            cm: "20x30",
            inch: "8x12",
            key: "20x60"
        },
        {
            cm: "25x38",
            inch: "10x15",
            key: "25x75"
        }
    ]

    const squareSizes = [
        {
            cm: "20x20",
            inch: "8x8",
            key: "20x40"
        },
        {
            cm: "25x25",
            inch: "10x10",
            key: "25x50"
        }
    ]

    useEffect(() => {
        fetch("https://shop.phototailors.com/api/public/prices", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(function(response) {
            if (response.status !== 200) {
                return null
            }
            return response.json()
        }).then(function(data) {
            setPrices(data);
        }).catch(function(ex) {
        })
    },[])

    if(!prices){
        return null;
    }

    return (
      <div id="pricing" className={styles.main}>
          <Title title="Pricing" subtitle={(
            <div>
                We have convenient pricing options to meet your specific requirements.
                <br/>
                Check out our predetermined offer below:
            </div>
          )}/>

          <div className={styles.selectors}>
              <div className={styles.selectorLeft}>
                  <h4 className={styles.head}>MEASUREMENTS</h4>
                  <div className={styles.buttons}>
                      <div className={styles.button} onClick={() => setMeasurement("CM")}>
                          <div
                            className={classnames(buttonStyles.smallButton, { [buttonStyles.selected]: measurement === "CM" })}>CM
                          </div>
                      </div>
                      <div className={styles.button} onClick={() => setMeasurement("INCH")}>
                          <div
                            className={classnames(buttonStyles.smallButton, { [buttonStyles.selected]: measurement === "INCH" })}>INCH
                          </div>
                      </div>
                  </div>
              </div>
              <div className={styles.selectorRight}>
                  <h4 className={styles.head}>CURRENCY</h4>
                  <div className={styles.buttons}>
                      <div className={styles.button} onClick={() => setCurrency("USD")}>
                          <div
                            className={classnames(buttonStyles.smallButton, { [buttonStyles.selected]: currency === "USD" })}>USD
                          </div>
                      </div>
                      <div className={styles.button} onClick={() => setCurrency("EUR")}>
                          <div
                            className={classnames(buttonStyles.smallButton, { [buttonStyles.selected]: currency === "EUR" })}>EUR
                          </div>
                      </div>
                      <div className={styles.button} onClick={() => setCurrency("AUD")}>
                          <div
                            className={classnames(buttonStyles.smallButton, { [buttonStyles.selected]: currency === "AUD" })}>AUD
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className={styles.configWrapper}>


              <PricingPanel
                title={"FLAP COVER"}
                prices={prices}
                images={images}
                imageConfig={pricingFlap}
                measurement={measurement}
                currency={currency}
                currenciesSymbols={currenciesSymbols}
                sizes={flapSizes}
                selectedSizeId="20x60"

                photosPerSpread={3}
                backgroundStyle={styles.rowWrapperLeft}

                includedSpreads={5}

                minPhotoSides={5}
                maxPhotoSides={45}

                minSides={20}
                maxSides={60}

              />

              <PricingPanel
                title={"SQUARE COVER"}
                prices={prices}
                images={images}
                imageConfig={pricingSquare}
                measurement={measurement}
                currency={currency}
                currenciesSymbols={currenciesSymbols}
                sizes={squareSizes}
                backgroundStyle={styles.rowWrapper}
                selectedSizeId="20x40"

                photosPerSpread={2}

                minPhotoSides={5}
                maxPhotoSides={45}

                includedSpreads={5}

                minSides={20}
                maxSides={60}

              />
          </div>
      </div>
    )
}