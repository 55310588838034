import React from "react"

import styles from "./radioGroup.module.css"

export default function RadioGroup({ name, items, selectedId, onValueSelected }) {
    const getItemsElement = () => {
        if (!items || items.length === 0) {
            return null
        }

        return items.map(item => {
            return (
              <label key={item.id} className={styles.checkboxContainer} onChange={(e)=>onSelected(e.target.value)}>
                  <div className={styles.checkboxValue}>
                      <div className={styles.checkboxValueLarge}>{item.label}</div>
                      <div className={styles.labelSubtitle}>{item.sublabel}</div>
                  </div>
                  <input type="radio" name={name} value={item.value} defaultChecked={item.id === selectedId}/>
                  <span className={styles.checkboxCheckmark}></span>
              </label>
            )
        })
    }

    function onSelected(value) {
        if (onValueSelected) {
            onValueSelected(value)
        }
    }

    const itemElement = getItemsElement()

    return (
      <div className={styles.main}>
          {itemElement}
      </div>
    )
}