const { useEffect } = require("react")

export function useRecaptcha() {
    useEffect(() => {
        setTimeout(()=>{
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LcLkOoZAAAAADp4ux6E5KJkfNBXabBh2fHFYQH-';
            script.id = 'googleRecaptcha';
            document.body.appendChild(script);
        }, 5000)
    }, []);
}