import React from "react"
import styles from "./leather.module.css"
import Title from "../title"

export default function Leather({ images }) {
    return (
      <div className={styles.main}>

          <div className={styles.title}>
              <Title title='Sustainability'
                     subtitle='Because we care'/>
          </div>

          <div className={styles.body}>
              While we love the elegance and the feel of leather, we prioritize sustainability and aim to provide
              our customers with an eco-conscious alternative.
              <br/>
              <br/>
              Therefore, we encourage you to opt for eco-leather for your albums, as it perfectly mimics the
              texture and conditions of natural leather while minimizing the environmental impact. However, if
              you are keen on choosing natural leather, we offer the option as a byproduct.
          </div>
      </div>
    )
}
