import React from "react"
import styles from "./empower.module.css"
import Title from "../title"

export default function Empower({ images }) {
    return (
      <div className={styles.main}>

          <div className={styles.title}>
              <Title title='Handcrafted for you'
                     subtitle='A project that supports and empowers women'/>
          </div>

          <div className={styles.body}>
              We take pride in working exclusively with wonderful and skilled female artisans that have mastered
              the art of processing leather and turning raw, ethically-sourced materials into works of art.
              <br/>
              <br/>
              We aim
              to provide you with the antidote to buying mass-produced products that lack personality and
              individuality while celebrating and supporting the artistic community at all levels.
          </div>
      </div>
    )
}
