import React from "react"
import styles from "./swatch.module.css"
import * as classnames from "classnames"
import Img from "gatsby-image"

export default function Swatch({small, image, callbackData, onSwatchSelected}) {
    let className = classnames(styles.main, { [styles.small]: small })
    let alt = image.childImageSharp.fluid.src.split('/');
    alt = alt[alt.length -1];
    alt = alt.split('.')[0];
    alt = alt.replace(/-/g, " ");

    return (
      <div className={className} onClick={()=>onSwatchSelected(callbackData)}>
          <Img objectFit="contain"
               fluid={image.childImageSharp.fluid} alt={alt}/>
      </div>
    )
}
