import React, { useState } from "react"
import styles from "./swatchSelector.module.css"
import Swatch from "./swatch"
import * as classnames from "classnames"

export default function SwatchSelector({ small, items, images, onSwatchSelected }) {
    const [currentSwatch, setCurrentSwatch] = useState(items[0].imageDisplay)
    if (!items) {
        return null
    }

    function handleSwatchSelected(swatch) {
        onSwatchSelected(swatch)
        setCurrentSwatch(swatch)
    }

    return (
      <div className={styles.main}>
          {
              items.map(item => {
                  let className = classnames(styles.swatch, { [styles.small]: small, [styles.selected]: currentSwatch === item.imageDisplay })

                  return (
                    <div key={item.thumb} className={className}>
                        <Swatch callbackData={item.imageDisplay} image={images[item.thumb]} small={small}
                                onSwatchSelected={swatch => handleSwatchSelected(swatch)}/>
                    </div>
                  )
              })
          }
      </div>
    )
}
