import React from "react"
import styles from "./albumtypes.module.css"
import Img from "gatsby-image"
import Title from "../title"

export default function AlbumTypes({ images }) {
    return (
      <div id='album-types' className={styles.main}>
          <div className={styles.title}>
              <Title title={"Album Types"} subtitle='Our album types are meant to provide you with two options, depending on your preference.'/>
          </div>

          <div className={styles.wrapper}>
              <div className={styles.sideLeft}>
                  <div className={styles.titleRight}>
                      <h2>Printed Albums</h2>
                  </div>
                  <div className={styles.textLeft}>
                      If you opt for a printed album, you get a stunning book ready to be offered as a gift or gain its place
                      of honor in your home.
                      <br/>
                      <br/>
                      Our experienced curators combine your photographs to craft a visual story
                      that will leave the viewer mesmerized.
                  </div>
                  <div className={styles.image}>
                      <Img objectFit="contain"
                           fluid={images.printedAlbums.childImageSharp.fluid} alt="Printed Albums"/>
                  </div>
              </div>

              <div className={styles.sideRight}>
                  <div className={styles.titleLeft}>
                      <h2>Empty Albums</h2>
                  </div>
                  <div className={styles.textRight}>
                      The empty album option is ideal for those who love to add their creative input in everything they do.
                      <br/>
                      <br/>
                      You get to fill the blank canvases with your photographs and combine them as you please in order to
                      craft your own visual narrative.
                  </div>
                  <div className={styles.image}>
                      <Img objectFit="contain"
                           fluid={images.emptyAlbums.childImageSharp.fluid} alt="Empty Albums"/>
                  </div>
              </div>


          </div>
      </div>
    )
}
