import React, { useState } from "react"
import styles from "./newsletter.module.css"
import Title from "../title"
import SquareInput from "../squareInput"
import buttonStyles from "../../styles/buttons.module.css"
import validateEmail from "../../utils/checkEmail"
import * as classnames from "classnames"

export default function Newsletter() {
    const [email, setEmail] = useState("")

    const [sending, setSending] = useState(false)

    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()

    async function onSubscribeClicked() {
        setErrorMessage(null)
        setSuccessMessage(null)
        setSending(true)

        if (!email && email.length < 3) {
            setErrorMessage("Please enter your email address")
            setSending(false)
            return
        }

        const isEmailValid = validateEmail(email)

        if (!isEmailValid) {
            setErrorMessage("Please use a valid email address")
            setSending(false)
            return
        }

        const token = await getRCT()

        const payload = {
            email: email,
            token: token
        }

        const formStatus = await submitForm(payload)
        setSending(false)
        setEmail("")

        if (formStatus.err) {
            setErrorMessage(formStatus.message)
        }

        if (formStatus.err === false) {
            setSuccessMessage(formStatus.message)
        }
    }

    function getRCT() {
        return new Promise(resolve => {
            window.grecaptcha.execute("6LcLkOoZAAAAADp4ux6E5KJkfNBXabBh2fHFYQH-", { action: "submit" }).then(token => {
                return resolve(token)
            })
        })
    }

    function submitForm(payload) {
        return new Promise(resolve => {
            fetch("https://shop.loribooks.com/api/public/subscribe", {
                // fetch("http://localhost:4000/public/subscribe", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(function(response) {
                if (response.status !== 200) {
                    return resolve({ err: true, message: "There was a problem subscribing you, please try again later" })
                }
                return response.json()
            }).then(function(data) {
                return resolve({ err: false, message: `You have been subscribed to our newsletter` })
            }).catch(function(ex) {
                return resolve({ err: true, message: "There was a problem subscribing you, please try again later" })
            })
        })
    }

    return (
      <div className={styles.main}>
          <div className={styles.title}>
              <Title title="Newsletter" subtitle="Subscribe now to receive the latest news and updates"/>
          </div>

          {successMessage &&
          <div className={styles.successMessage}>{successMessage}</div>
          }
          {errorMessage &&
          <div className={styles.errorMessage}>{errorMessage}</div>
          }

          <div className={styles.inputFields}>
              <div className={styles.input}>
                  <SquareInput placeholder='E-mail address' type='email' value={email}
                               onChange={value => setEmail(value)}/>
              </div>
              <div className={styles.button}>
                  <div className={classnames(buttonStyles.largeButton, { [buttonStyles.disabled]: sending })}
                       onClick={onSubscribeClicked}>
                      {sending === true ? "SUBSCRIBING..." : "SUBSCRIBE"}
                  </div>
              </div>

          </div>

      </div>
    )
}
