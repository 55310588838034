import React, { useState } from "react"
import styles from "./coverStyles.module.css"
import Img from "gatsby-image"
import SwatchSelector from "../swatchSelector"
import Title from "../title"
import {
    largeFlapSwatches,
    largeSquareSwatches,
    smallFlapSwatches,
    smallSquareSwatches
} from "../../config/styleImages"

export default function CoverStyles({ images }) {
    const [flapImage, setFlapImage] = useState(largeFlapSwatches[0].imageDisplay);
    const [flapSmallImage, setFlapSmallImage] = useState(smallFlapSwatches[0].imageDisplay);
    const [squareImage, setSquareImage] = useState(largeSquareSwatches[0].imageDisplay);
    const [squareSmallImage, setSquareSmallImage] = useState(smallSquareSwatches[0].imageDisplay);

    return (
      <div id='cover-styles' className={styles.wrap}>
          <div className={styles.title}>
              <Title title={"Cover Styles"} subtitle='Our cover style options are handmade from supple leather to ensure a premium and elegant feel to
your stunning photo selection.'/>
          </div>

          <div className={styles.main}>
              <div className={styles.imageLeft}>
                  <Img  objectFit="contain"
                       fluid={images[flapImage].childImageSharp.fluid} alt="Flap Cover"/>
              </div>
              <div className={styles.textLeft}>
                  <div className={styles.textWrapper}>
                      <div>
                          <div className={styles.styleTitle}>
                              <h2>Flap Collection</h2>
                              <div className={styles.price}>from $139.99</div>
                          </div>
                          <div className={styles.mobileImage}>
                              <Img objectFit="contain"
                                   fluid={images[flapSmallImage].childImageSharp.fluid} alt="Flap Cover"/>
                          </div>
                          <div className={styles.mobileSwatch}>
                              <SwatchSelector small={true} images={images} items={smallFlapSwatches} onSwatchSelected={setFlapSmallImage}/>
                          </div>
                          <div className={styles.description}>
                              The flap style collection is designed to make the viewers feel like they’re unraveling a precious
                              secret, as the opening enhances the mystery touch.
                              <br/>
                              <br/>
                              The soft leather with a subtle vintage feel
                              completes the elegant look, along with the neutral palette you can choose from.
                          </div>
                      </div>
                      <div className={styles.swatchSelector}>
                          <SwatchSelector images={images} items={largeFlapSwatches} onSwatchSelected={setFlapImage}/>
                      </div>
                  </div>
              </div>
          </div>

          <div className={styles.main}>
              <div className={styles.imageLeft}>
                  <Img objectFit="contain"
                       fluid={images[squareImage].childImageSharp.fluid} alt="Square Cover"/>
              </div>
              <div className={styles.textRight}>
                  <div className={styles.textWrapper}>
                      <div>
                          <div className={styles.styleTitle}>
                              <h2>Square Collection</h2>
                              <div className={styles.price}>from $99.99</div>
                          </div>
                          <div className={styles.mobileImage}>
                              <Img objectFit="contain"
                                   fluid={images[squareSmallImage].childImageSharp.fluid} alt="Square Cover"/>
                          </div>
                          <div className={styles.mobileSwatch}>
                              <SwatchSelector small={true} images={images} items={smallSquareSwatches} onSwatchSelected={setSquareSmallImage}/>
                          </div>
                          <div className={styles.description}>
                              The square style collection encapsulates designs on the minimalist side, providing you with leather
                              and textile options.
                              <br/>
                              <br/>
                              In what regards the color palette, you can choose from neutral tones to vibrant
                              red, depending on your specific preference.
                          </div>
                      </div>
                      <div className={styles.swatchSelector}>
                          <SwatchSelector images={images} items={largeSquareSwatches} onSwatchSelected={setSquareImage}/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}
