import React from "react"

import styles from "./checkbox.module.css"

export default function Checkbox({ checked, onValueSelected }) {
    const name = Math.random().toString()

    return (
      <div className={styles.main}>
          <div className={styles.checkbox}>
              <input type="checkbox" id={name} className={styles.inputContainer} defaultChecked={checked} onChange={e=>onValueSelected(e.target.checked)} />
              <label htmlFor={name} className={styles.box}>
                  <div className={styles.checked}/>
                  <div className={styles.itemLabel}>show pricing per spread</div>
              </label>
          </div>
      </div>
    )
}