import React, { useState } from "react"
import Img from "gatsby-image"
import RadioGroup from "./radioGroup"
import Dropdown from "./dropdown"
import SwatchSelector from "./swatchSelector"
import buttonStyles from "../styles/buttons.module.css"
import styles from "./sections/pricing.module.css"
import { Link } from "gatsby"
import Checkbox from "./checkbox"

export default function PricingPanel({
                                         prices,
                                         title,
                                         backgroundStyle,
                                         images,
                                         imageConfig,
                                         sizes,
                                         selectedSizeId,
                                         measurement,
                                         currency,
                                         currenciesSymbols,
                                         minPhotoSides,
                                         maxPhotoSides,
                                         minSides,
                                         maxSides,
                                         photosPerSpread,
                                         includedSpreads
                                     }) {

    const photosToSides = (photos) => {
        photos = photos + (photosPerSpread - 1)
        const sides = Math.ceil(photos / photosPerSpread)
        return sides
    }


    const sidesToPhotosMax = (sides) => {
        const photos = (sides * photosPerSpread) - (photosPerSpread - 1)
        return photos
    }

    const [image, setImage] = useState(imageConfig[0].imageDisplay)
    const [currentSize, setCurrentSize] = useState(selectedSizeId)
    const [blockType, setBlockType] = useState("printed")
    const [currentNumSides, setCurrentNumSides] = useState(sidesToPhotosMax(minPhotoSides))
    const [mode, setMode] = useState("photos") // photos|spreads

    let priceList = prices[currency]

    if (blockType === "printed") {
        priceList = priceList["boudoir"]["size"]
    }
    if (blockType === "empty") {
        priceList = priceList["boudoir-empty"]["size"]
    }

    priceList = priceList[currentSize]


    const getPriceForNumPhotos = (numPhotos) => {
        const requiredSpreads = photosToSides(numPhotos) - includedSpreads;
        let price = priceList.base + (priceList.extraSpread * requiredSpreads)
        price = Math.round(price) - 0.01
        return price
    }
    const getPriceForNumSpreads = (numSpreads) => {
        let price = priceList.base + (priceList.extraSpread * (numSpreads - includedSpreads))
        price = Math.round(price) - 0.01
        return price
    }

    const getPriceForNumPages = (numPages) => {
        return priceList[numPages]
    }

    const sizesElement = [...sizes].map(item => {
        if (measurement === "CM") {
            return { id: item.key, value: item.key, label: `${item.cm} cm` }
        }
        if (measurement === "INCH") {
            return { id: item.key, value: item.key, label: `${item.inch} inch` }
        }
    })

    const getAllPhotosOptions = () => {
        const minPhotos = minPhotoSides * photosPerSpread - (photosPerSpread - 1)
        const maxPhotos = maxPhotoSides * photosPerSpread - (photosPerSpread - 1)
        let newPhotos = []

        let prices = {}
        let checkPrice = 0

        for (let i = minPhotos; i <= maxPhotos; i++) {
            let price = getPriceForNumPhotos(i)

            if (!prices[price]) {
                prices[price] = []
            }

            prices[price].push(i)
        }

        for (let i = minPhotos; i <= maxPhotos; i++) {
            let price = getPriceForNumPhotos(i)

            if (i === minPhotos) {
                newPhotos.push({
                    id: i,
                    value: i,
                    label: `Up to ${i} photos`,
                    price: `${currenciesSymbols[currency]} ${getPriceForNumPhotos(i)}`
                })
            }

            if (price !== checkPrice) {
                let itemsForPrice = prices[price]

                if (i !== minPhotos) {

                    newPhotos.push({
                        id: i,
                        value: i,
                        label: `${itemsForPrice[0]} to ${itemsForPrice[itemsForPrice.length - 1]} photos`,
                        price: `${currenciesSymbols[currency]} ${getPriceForNumPhotos(i)}`
                    })
                }

                checkPrice = price
            }
        }

        return newPhotos
    }

    const getAllPhotosInSpreadsOptions = () => {
        let newSpreads = []

        for (let i = minPhotoSides; i <= maxPhotoSides; i++) {
            newSpreads.push({
                id: i,
                value: i,
                label: `${i} spreads`,
                price: `${currenciesSymbols[currency]} ${getPriceForNumSpreads(i)}`
            })
        }

        return newSpreads
    }

    const getAllPagesOptions = () => {
        let pages = []
        for (let i = minSides; i <= maxSides; i += 10) {

            pages.push({
                id: i, value: i, label: `${i} pages`, price: `${currenciesSymbols[currency]} ${getPriceForNumPages(i)}`
            })

        }
        return pages
    }

    const getPageSelectorOptions = () => {
        if (blockType === "printed") {

            if (mode === "photos") {
                const data = getAllPhotosOptions()

                return (
                  <div className={styles.photoSelector}>
                      <div className={styles.photoSelectorQuestion}>How many photos do you have?</div>

                      <div className={styles.dropdown}>
                          <Dropdown selectedId={currentNumSides} onItemSelected={item => setCurrentNumSides(item)}
                                    items={data}/>
                      </div>
                  </div>
                )
            }

            if (mode === "spreads") {
                const data = getAllPhotosInSpreadsOptions()

                return (
                  <div className={styles.photoSelector}>
                      <div className={styles.photoSelectorQuestion}>How many spreads do you need?</div>

                      <div className={styles.dropdown}>
                          <Dropdown selectedId={currentNumSides} onItemSelected={item => setCurrentNumSides(item)}
                                    items={data}/>
                      </div>
                  </div>
                )
            }
        }
        if (blockType === "empty") {
            const data = getAllPagesOptions()

            return (
              <div className={styles.photoSelector}>
                  <div className={styles.photoSelectorQuestion}>How many pages do you want?</div>

                  <div className={styles.dropdown}>
                      <Dropdown selectedId={currentNumSides} onItemSelected={item => setCurrentNumSides(item)}
                                items={data}/>
                  </div>
              </div>
            )
        }
    }

    const pageSelectorOptions = getPageSelectorOptions()

    const onBlockTypeChanged = blockType => {
        switch (blockType) {
            case "printed":
                setCurrentNumSides(sidesToPhotosMax(minPhotoSides))
                break
            case "empty":
                setMode("photos")
                setCurrentNumSides(minSides)
                break
        }

        setBlockType(blockType)
    }

    const onModeChanged = value => {
        setMode(value ? "spreads" : "photos")

        switch (blockType) {
            case "printed":
                if (value) {
                    setCurrentNumSides(minPhotoSides)
                } else {
                    setCurrentNumSides(sidesToPhotosMax(minPhotoSides))
                }

                break
            case "empty":
                setCurrentNumSides(minSides)
                break
        }

        setBlockType(blockType)
    }

    let totalPrice = 0
    if (blockType === "printed") {
        if (mode === "photos") {
            totalPrice = getPriceForNumPhotos(currentNumSides)
        }
        if (mode === "spreads") {
            totalPrice = getPriceForNumSpreads(currentNumSides)
        }
    }
    if (blockType === "empty") {
        totalPrice = getPriceForNumPages(currentNumSides)
    }

    let alt = images[image].childImageSharp.fluid.src.split("/")
    alt = alt[alt.length - 1]
    alt = alt.split(".")[0]
    alt = alt.replace(/-/g, " ")

    const getShopNowUrl = () => {
        const size = sizes.find(item => item.key === currentSize).cm
        const type = blockType === "printed" ? "boudoir" : "boudoir-empty"
        const collection = title === "FLAP COVER" ? "flap" : "square"
        const sides = currentNumSides
        const material = imageConfig.find(item => item.imageDisplay === image).id

        const url = `https://shop.loribooks.com/seed?size=${size}&type=${type}&sides=${sides}&collection=${collection}&material=${material}&currency=${currency}`

        return url
    }

    return (
      <div className={styles.config}>
          <Img objectFit="contain"
               fluid={images[image].childImageSharp.fluid} alt={alt}/>

          <div className={backgroundStyle}>
              <div className={styles.row}>
                  <h2 className={styles.configTitle}>{title}</h2>

                  <div className={styles.configSizes}>
                      <RadioGroup name={`${title}-size-selector`} onValueSelected={v => setCurrentSize(v)}
                                  selectedId={currentSize} items={sizesElement}/>
                  </div>

                  <div className={styles.configTypes}>
                      <RadioGroup name={`${title}-type-selector`} selectedId="printed"
                                  onValueSelected={onBlockTypeChanged} items={[
                          { id: "printed", value: "printed", label: "Printed album", sublabel: "Design included" },
                          { id: "empty", value: "empty", label: "Empty album", sublabel: "Prints not included" }
                      ]}/>
                  </div>

                  {pageSelectorOptions}

                  <div className={styles.swatches}>
                      <SwatchSelector small={true} images={images} items={imageConfig}
                                      onSwatchSelected={setImage}/>
                  </div>

                  <div className={styles.estimatedPrice}>
                      <h3>{currenciesSymbols[currency]}</h3>
                      <div className={styles.price}>{totalPrice}</div>
                  </div>

                  <div className={styles.orderButton}>
                      <Link id="pricing-shop-button" to={getShopNowUrl()} className={buttonStyles.button}>ORDER
                          NOW</Link>
                  </div>

                  {blockType === "printed" &&
                  <div className={styles.spreadSwitch}>
                      <Checkbox checked={mode === "spreads"} onValueSelected={onModeChanged}/>
                  </div>
                  }

              </div>

          </div>
      </div>
    )
}
