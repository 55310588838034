import React, { useEffect, useState } from "react"

import styles from "./dropdown.module.css"
import EscapeOutside from "./escapeOutside"

export default function Dropdown({ items, search, searchPlaceholder, selectedId, onItemSelected }) {
    if (selectedId === null || selectedId === undefined) {
        selectedId = 1
    }

    const [expanded, setExpanded] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedItem, setSelectedItem] = useState(items.find(i => i.id === selectedId))
    const [renderedItems, setRenderedItems] = useState(items)

    useEffect(() => {
        setSelectedItem(items.find(i => i.id === selectedId))
    }, [items])

    if (!items || items.length === 0) {
        return null
    }

    const getBodyItems = () => {
        const itemsToRender = search ? renderedItems : items
        return itemsToRender.map(item => {
            return (
              <div key={item.id} className={styles.bodyItem} onClick={() => onItemClicked(item)}>
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.headerSpacer}/>
                  <div className={styles.bodyItemPrice}>{item.price}</div>
              </div>
            )
        })
    }

    const onItemClicked = (item) => {
        setSelectedItem(item)
        setExpanded(false)
        if (onItemSelected) {
            onItemSelected(item.value)
        }
    }

    const onDropDownOpenClicked = (value) => {
        setSearchTerm('');
        setRenderedItems(items);
        setExpanded(value);
    }

    const onSearchTermChanged = (searchTerm) => {
        setSearchTerm(searchTerm)
        let filteredItems = [...items].filter(item => item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0)

        setRenderedItems(filteredItems)
    }

    const bodyItems = getBodyItems()

    return (
      <EscapeOutside onEscapeOutside={() => setExpanded(false)}>
          <div className={styles.wrapper}>
              <div className={styles.main}>
                  <div className={styles.header} onClick={() => onDropDownOpenClicked(!expanded)}>
                      <div className={styles.headerValue}>
                          <div>{selectedItem.label}</div>
                          <div className={styles.headerSpacer}/>
                          <div>{selectedItem.price}</div>
                      </div>

                      <div className={styles.headerArrow}/>
                  </div>
                  {expanded &&

                  <div className={styles.body}>
                      <div className={styles.bodyWrapper}>
                          {search &&
                          <div className={styles.search}><input className={styles.input} placeholder={searchPlaceholder}
                                                                value={searchTerm} type='search'
                                                                onChange={(e) => onSearchTermChanged(e.target.value)}/>
                          </div>
                          }
                          {bodyItems}
                      </div>
                  </div>
                  }
              </div>
          </div>
      </EscapeOutside>
    )
}