import React from "react"
import styles from "./process.module.css"
import Title from "./title"
import buttonStyles from "../styles/buttons.module.css"
import { Link } from "gatsby"

export default function Process() {
    return (
      <div className={styles.main}>
          <div className={styles.title}>
              <Title title="Our Process for Printed Albums" subtitle="Simple as it gets"/>
          </div>


          <div className={styles.wrapper}>

              <div className={styles.line}></div>

              <div className={styles.items}>

                  <div className={styles.smallItem}>
                      <div className={styles.smallDot}/>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 1</div>
                      <div className={styles.description}>Choose the album size, cover colour and text.
                      </div>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 2</div>
                      <div className={styles.description}>Upload your photos and complete the  payment process.
                      </div>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 3</div>
                      <div className={styles.description}>Within 72 hours you will receive a link where you can review the design layout.
                      </div>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 4</div>
                      <div className={styles.description}>Repeat Step 3 until you're happy with the result.
                      </div>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 5</div>
                      <div className={styles.description}>It takes us 2-3 days to make the album after the design is approved.
                      </div>
                  </div>

                  <div className={styles.item}>
                      <div className={styles.dot}/>
                      <div className={styles.step}>Step 6</div>
                      <div className={styles.description}>We ship the album and provide the tracking number.
                      </div>
                  </div>

                  <div className={styles.smallItem}>
                      <div className={styles.smallDot}/>
                  </div>
              </div>
          </div>

          <div className={styles.button}>
              <Link id="process-shop-button" to="https://shop.loribooks.com" className={buttonStyles.largeButton}>ORDER NOW</Link>
          </div>

      </div>
    )
}
