import React from "react"
import styles from "./testimonials.module.css"
import Title from "../title"
import Img from "gatsby-image"

export default function Testimonials({ images }) {
    return (
      <div id="gallery" className={styles.main}>

          <div className={styles.title}>
              <Title title='Testimonials'
                     subtitle='Here’s what our happy customers have to say about their experience with Lori Books:'/>
          </div>

          <div className={styles.body}>
              <div className={styles.bodyLeft}>
                  <div className={styles.bodyLeftWrapper}>
                      <div className={styles.startQuote}>“</div>
                      <div className={styles.testimonial}>The quality of this album is absolutely outstanding. Before
                          ordering, I asked several questions about the product, and even though our time zones are
                          different, I had a response within minutes. The quality of the pages and photos are amazing. I
                          ordered and received within 3 days. I highly recommend!
                      </div>
                      <div className={styles.endQuote}>“</div>
                      <div className={styles.name}>Brittany</div>
                  </div>
              </div>
              <div className={styles.bodyRight}>
                  <div className={styles.bodyRightWrapper}>
                      <Img objectFit="contain"
                           fluid={images.testimonial1.childImageSharp.fluid} alt="Our work"/>
                  </div>
              </div>
          </div>
      </div>
    )
}
