const largeFlapSwatches = [
    { thumb: 'swatchFlapVintage', imageDisplay: 'flapVintage' },
    { thumb: 'swatchFlapMaroon', imageDisplay: 'flapMaroon' },
    { thumb: 'swatchFlapCognac', imageDisplay: 'flapCognac' },
    { thumb: 'swatchFlapGray', imageDisplay: 'flapGray' },
    { thumb: 'swatchFlapBlack', imageDisplay: 'flapBlack' },
]

const smallFlapSwatches = [
    { thumb: 'swatchFlapVintage', imageDisplay: 'flapSmallVintage'},
    { thumb: 'swatchFlapMaroon', imageDisplay: 'flapSmallMaroon' },
    { thumb: 'swatchFlapCognac', imageDisplay: 'flapSmallCognac' },
    { thumb: 'swatchFlapGray', imageDisplay: 'flapSmallGray' },
    { thumb: 'swatchFlapBlack', imageDisplay: 'flapSmallBlack' },
]

const largeSquareSwatches = [
    { thumb: 'swatchSquareGold', imageDisplay: 'squareGold' },
    { thumb: 'swatchSquareScarlet', imageDisplay: 'squareScarlet' },
    { thumb: 'swatchSquareGunmetal', imageDisplay: 'squareGunmetal' },
    { thumb: 'swatchSquareMetallicBlack', imageDisplay: 'squareMetallicBlack' },
    { thumb: 'swatchSquareBlack', imageDisplay: 'squareBlack' },
]

const smallSquareSwatches = [
    { thumb: 'swatchSquareGold', imageDisplay: 'squareSmallGold' },
    { thumb: 'swatchSquareScarlet', imageDisplay: 'squareSmallScarlet' },
    { thumb: 'swatchSquareGunmetal', imageDisplay: 'squareSmallGunmetal' },
    { thumb: 'swatchSquareMetallicBlack', imageDisplay: 'squareSmallMetallicBlack' },
    { thumb: 'swatchSquareBlack', imageDisplay: 'squareSmallBlack' },
]

const pricingFlap = [
    { thumb: 'swatchFlapVintage', imageDisplay: 'pricingFlapVintage' , id:143},
    { thumb: 'swatchFlapMaroon', imageDisplay: 'pricingFlapMaroon' , id:120},
    { thumb: 'swatchFlapCognac', imageDisplay: 'pricingFlapCognac' , id:147},
    { thumb: 'swatchFlapGray', imageDisplay: 'pricingFlapGray', id:146 },
    { thumb: 'swatchFlapBlack', imageDisplay: 'pricingFlapBlack' , id:88},
]

const pricingSquare = [
    { thumb: 'swatchSquareGold', imageDisplay: 'pricingSquareGold' , id:122},
    { thumb: 'swatchSquareScarlet', imageDisplay: 'pricingSquareScarlet' , id:6},
    { thumb: 'swatchSquareGunmetal', imageDisplay: 'pricingSquareGunmetal', id:123 },
    { thumb: 'swatchSquareMetallicBlack', imageDisplay: 'pricingSquareMetallicBlack', id:127 },
    { thumb: 'swatchSquareBlack', imageDisplay: 'pricingSquareBlack' , id:17},
]

module.exports = {
    largeFlapSwatches, smallFlapSwatches, largeSquareSwatches,smallSquareSwatches, pricingFlap, pricingSquare
}