import React, { useState } from "react"
import styles from "./faqComponent.module.css"

export default function FAQPart({ item }) {
    const [isExpanded, setExpanded] = useState(false)


    return (
      <div key={item.question} className={styles.item}>
          <div className={styles.head} onClick={() => setExpanded(!isExpanded)}>
              {(!isExpanded) &&
              <div className={styles.toggle}></div>
              }
              {(isExpanded) &&
              <div className={styles.toggleCollapsed}></div>
              }
              <div className={styles.title}>{item.question}</div>
          </div>
          {(isExpanded) &&
          <div className={styles.answer}>{item.answer}</div>
          }
      </div>
    )
}
