import React from "react"
import styles from "./mission.module.css"
import Img from "gatsby-image"

export default function Mission({ images }) {
    return (
      <div className={styles.main}>
          <Img className={styles.missionImage} objectFit="contain"
               fluid={images.mainImage.childImageSharp.fluid} alt="Lori Books Boudoir Albums"/>
          <div className={styles.missionTextWrapper}>
              <div className={styles.missionBackground}>
                  <div className={styles.missionText}>
                      <div className={styles.missionHead}>
                          <h3>OUR MISSION</h3>
                      </div>
                      <br/>
                      <br/>
                      <br/>
                      <div>
                          At Lori Books, our mission is to empower and support everyone to explore and reveal
                          their sensuality
                          in front of the camera, as there’s no boundary when it comes to the human body’s raw
                          beauty.
                          <br/>
                          <br/>
                          Our custom-made photo albums serve as timeless reminders of the beauty and power you
                          possess. Of
                          course, they can play the role of sensual gifts to celebrate your unique connection
                          with your
                          significant other.
                          <br/>
                          <br/>
                          You’re the best gift in someone’s life. Our personalized boudoir albums come next.

                      </div>
                  </div>
              </div>
          </div>
      </div>

    )
}
