import React from "react"
import styles from "./albumDetails.module.css"
import Title from "../title"
import Img from "gatsby-image"

export default function AlbumDetails({images}) {
    return (
      <div id="album-details" className={styles.main}>
          <Title title={"Album Details"} subtitle="We use a variety of techniques to breathe personality into our albums"/>

          <div className={styles.wrapper}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.handcrafted.childImageSharp.fluid} alt="Handcrafted Album"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Handcrafted</h2>
                  <div className={styles.description}>
                      Just like every human body is unique, so are our customized boudoir albums made to
                      celebrate our individuality. Each and every album is carefully handcrafted by our skilled
                      artisans, using exclusively premium materials.
                  </div>
              </div>
          </div>

          <div className={`${styles.wrapper} ${styles.textLeft}`}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.debossing.childImageSharp.fluid} alt="Debossed Album"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Debossing</h2>
                  <div className={styles.description}>
                      Through debossing, we create recessed relief images and designs in paper, leather, and any
                      other material to add depth to your custom-made album’s design. If it’s meant to be a gift
                      to celebrate a special occasion, debossing will make the moment even more memorable by
                      adding a lovely personal touch.
                  </div>
              </div>
          </div>

          <div className={styles.wrapper}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.vellum.childImageSharp.fluid} alt="Vellum Page Album"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Vellum Page</h2>
                  <div className={styles.description}>
                      We mix vellum papers with thick pages, as they add an irresistible touch of daintiness
                      when combined with hard materials. You can imprint a special message, quote or lyrics of
                      your choice or pick one (or more) from our suggestions.
                  </div>
              </div>
          </div>

          <div className={`${styles.wrapper} ${styles.textLeft}`}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.design.childImageSharp.fluid} alt="Album Design"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Design</h2>
                  <div className={styles.description}>
                      Our boudoir albums are crafted to unfold as a compelling visual story in front of the
                      eager viewer. We make the best out of every little detail, always.

                  </div>
              </div>
          </div>

          <div className={styles.wrapper}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.printQuality.childImageSharp.fluid} alt="Album Quality Print"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Print Quality</h2>
                  <div className={styles.description}>
                      We print on premium Fuji photographic paper, designed exclusively for high-quality prints
                      that require intense colors. It renders smooth gradients, giving images a stunning
                      vibrance on lighter tones. We put two formats at your disposal: square and landscape with
                      two size options for each format.
                  </div>
              </div>
          </div>

          <div className={`${styles.wrapper} ${styles.textLeft}`}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.layflat.childImageSharp.fluid} alt="Lay Flat Album"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Lay Flat Technology
                  </h2>
                  <div className={styles.description}>
                      Just like a good story shouldn’t be interrupted, our binding technology ensures that the
                      viewer will be absorbed by the sequence of images, without any distraction spoiling the
                      charm. The binding tech ensures there’s no gap between pages, being the perfect option for
                      showcasing impressive panoramic spreads without disruption.

                  </div>
              </div>
          </div>

          <div className={styles.wrapper}>
              <div className={styles.image}>
                  <Img objectFit="contain"
                       fluid={images.thickPages.childImageSharp.fluid} alt="Thick Pages Album"/>
              </div>
              <div className={styles.text}>
                  <h2 className={styles.title}>Thick Pages</h2>
                  <div className={styles.description}>
                      We fuse the printed images onto a hard and heavyweight board core. The robust core doesn’t
                      bend or curl, giving your album a luxury feel while ensuring quality that passes the test
                      of time.
                  </div>
              </div>
          </div>
      </div>
    )
}
