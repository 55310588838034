import React, { useState } from "react"
import styles from "./faqComponent.module.css"
import FAQPart from "./faqPart"

export default function FAQComponent({ item }) {

    const getItemsElements = () => {
        return item.items.map(it => {
            return (
                <FAQPart item={it}/>
            )
        })
    }

    const itemsElements = getItemsElements()

    return (
      <div className={styles.main}>
          <div>
              {itemsElements}
          </div>
      </div>
    )
}
