import React from "react"
import { graphql } from "gatsby"
import Page from "../components/page"
import Pricing from "../components/sections/pricing"
import ShippingEstimator from "../components/sections/shippingEstimator"
import Testimonials from "../components/sections/testimonials"
import Newsletter from "../components/sections/newsletter"
import FAQ from "../components/sections/faq"
import Contact from "../components/sections/contact"
import Footer from "../components/sections/footer"
import Process from "../components/process"
import Mission from "../components/sections/mission"
import CoverStyles from "../components/sections/coverStyles"
import AlbumTypes from "../components/sections/albumTypes"
import AlbumDetails from "../components/sections/albumDetails"
import GoToTopButton from "../components/goToTopButton"
import { useRecaptcha } from "../hooks/loadRecaptcha"
import Empower from "../components/sections/empower"
import Leather from "../components/sections/leather"

export default function Home({ location, data }) {

    useRecaptcha();

    return (
      <Page title={"Lori Books – Boudoir Photo Albums"} location={location}>
          <div>
              <Mission images={data}/>

              <CoverStyles images={data}/>

              <AlbumTypes images={data}/>

              <Process/>

              <AlbumDetails images={data}/>

              <Pricing images={data}/>

              <ShippingEstimator/>

              <Testimonials images={data}/>

              <Newsletter/>

              <FAQ/>

              <Empower/>

              <Leather/>

              <Contact/>

              <Footer images={data}/>

              <GoToTopButton/>

          </div>


      </Page>
    )
}

export const query = graphql`
  query {
    mainImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    squareCollection: file(relativePath: { eq: "square-black.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }  
    
    
    squareCollectionMobile: file(relativePath: { eq: "square-mobile-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    flapBlack: file(relativePath: { eq: "flap-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    flapCognac: file(relativePath: { eq: "flap-cognac.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    flapGray: file(relativePath: { eq: "flap-gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    flapVintage: file(relativePath: { eq: "flap-vintage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG 
        }
      }
    }
    
    flapMaroon: file(relativePath: { eq: "flap-maroon.jpg" }) {
      childImageSharp { 
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     flapSmallBlack: file(relativePath: { eq: "flap-small-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    flapSmallCognac: file(relativePath: { eq: "flap-small-cognac.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    flapSmallGray: file(relativePath: { eq: "flap-small-gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     flapSmallMaroon: file(relativePath: { eq: "flap-small-maroon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    flapSmallVintage: file(relativePath: { eq: "flap-small-vintage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    
     squareBlack: file(relativePath: { eq: "square-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     squareGold: file(relativePath: { eq: "square-gold.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     squareMetallicBlack: file(relativePath: { eq: "square-metallic-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     squareScarlet: file(relativePath: { eq: "square-scarlet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    squareGunmetal: file(relativePath: { eq: "square-gunmetal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    squareSmallBlack: file(relativePath: { eq: "square-small-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     squareSmallGold: file(relativePath: { eq: "square-small-gold.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     squareSmallMetallicBlack: file(relativePath: { eq: "square-small-metallic-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     squareSmallGunmetal: file(relativePath: { eq: "square-small-gunmetal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    
     squareSmallScarlet: file(relativePath: { eq: "square-small-scarlet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    
    printedAlbums: file(relativePath: { eq: "album-printed.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
     emptyAlbums: file(relativePath: { eq: "album-empty.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    handcrafted: file(relativePath: { eq: "album-details-handcrafted.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    debossing: file(relativePath: { eq: "album-details-deboss.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    vellum: file(relativePath: { eq: "album-details-vellum-page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     design: file(relativePath: { eq: "album-details-design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    printQuality: file(relativePath: { eq: "album-details-print-quality.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     layflat: file(relativePath: { eq: "album-details-lay-flat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     thickPages: file(relativePath: { eq: "album-details-thick-pages.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     pricingFlapBlack: file(relativePath: { eq: "pricing-flap-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    pricingFlapCognac: file(relativePath: { eq: "pricing-flap-cognac.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    pricingFlapGray: file(relativePath: { eq: "pricing-flap-gray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    pricingFlapVintage: file(relativePath: { eq: "pricing-flap-vintage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    pricingFlapMaroon: file(relativePath: { eq: "pricing-flap-maroon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    
     pricingSquareBlack: file(relativePath: { eq: "pricing-square-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    pricingSquareGold: file(relativePath: { eq: "pricing-square-gold.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     pricingSquareGunmetal: file(relativePath: { eq: "pricing-square-gunmetal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     pricingSquareMetallicBlack: file(relativePath: { eq: "pricing-square-metallic-black.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
     pricingSquareScarlet: file(relativePath: { eq: "pricing-square-scarlet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    testimonial1: file(relativePath: { eq: "testimonials.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
     fbIcon: file(relativePath: { eq: "fb-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    inIcon: file(relativePath: { eq: "in-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    piIcon: file(relativePath: { eq: "pi-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    twIcon: file(relativePath: { eq: "tw-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchFlapBlack: file(relativePath: { eq: "flap-black-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchFlapCognac: file(relativePath: { eq: "flap-cognac-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchFlapGray: file(relativePath: { eq: "flap-gray-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchFlapMaroon: file(relativePath: { eq: "flap-maroon-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchFlapVintage: file(relativePath: { eq: "flap-vintage-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchSquareBlack: file(relativePath: { eq: "square-black-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchSquareGold: file(relativePath: { eq: "square-gold-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchSquareGunmetal: file(relativePath: { eq: "square-gunmetal-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchSquareMetallicBlack: file(relativePath: { eq: "square-metallic-black-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
    swatchSquareScarlet: file(relativePath: { eq: "square-scarlet-swatch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
   }
`